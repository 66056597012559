
    import { defineAsyncComponent } from '#imports'
    
import BlokkliComponent_focus_teaser_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/FocusTeaser/index.vue'
import BlokkliComponent_button_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/Button/index.vue'
import BlokkliComponent_button_mjml from '/builds/bazinga/bs.ch/frontend/components/Paragraph/Button/mjml.vue'
import BlokkliComponent_dynamic_teaser_list_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/DynamicTeaserList/index.vue'
import BlokkliComponent_event_place_contingent_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/EventPlaceContingent/index.vue'
import BlokkliComponent_expand_section_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/ExpandSection/index.vue'
import BlokkliComponent_external_contact_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/ExternalContact/index.vue'
import BlokkliComponent_government_contact_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/GovernmentContact/index.vue'
import BlokkliComponent_horizontal_line_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/HorizontalLine/index.vue'
import BlokkliComponent_horizontal_line_mjml from '/builds/bazinga/bs.ch/frontend/components/Paragraph/HorizontalLine/mjml.vue'
import BlokkliComponent_image_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/Image/index.vue'
import BlokkliComponent_image_mjml from '/builds/bazinga/bs.ch/frontend/components/Paragraph/Image/mjml.vue'
import BlokkliComponent_information_link_attachment_Expandable from '/builds/bazinga/bs.ch/frontend/components/Paragraph/InformationLinkAttachment/Expandable.vue'
import BlokkliComponent_information_link_attachment_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/InformationLinkAttachment/index.vue'
import BlokkliComponent_link_list_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/LinkList/index.vue'
import BlokkliComponent_next_best_action_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/NextBestAction/index.vue'
import BlokkliComponent_place_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/Place/index.vue'
import BlokkliComponent_quote_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/Quote/index.vue'
import BlokkliComponent_section_title_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/SectionTitle/index.vue'
import BlokkliComponent_section_title_mjml from '/builds/bazinga/bs.ch/frontend/components/Paragraph/SectionTitle/mjml.vue'
import BlokkliComponent_table_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/Table/index.vue'
import BlokkliComponent_teaser_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/Teaser/index.vue'
import BlokkliComponent_teaser_mjml from '/builds/bazinga/bs.ch/frontend/components/Paragraph/Teaser/mjml.vue'
import BlokkliComponent_teaser_list_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/TeaserList/index.vue'
import BlokkliComponent_text_index from '/builds/bazinga/bs.ch/frontend/components/Paragraph/Text/index.vue'
import BlokkliComponent_text_mjml from '/builds/bazinga/bs.ch/frontend/components/Paragraph/Text/mjml.vue'
import BlokkliComponent_blokkli_fragment_index from '/builds/bazinga/bs.ch/frontend/node_modules/@blokkli/editor/dist/runtime/components/Blocks/Fragment/index.vue'

const global: Record<string, any> = {
  block_focus_teaser: BlokkliComponent_focus_teaser_index,
  block_button: BlokkliComponent_button_index,
  block_button__field_list_type_email: BlokkliComponent_button_mjml,
  block_dynamic_teaser_list: BlokkliComponent_dynamic_teaser_list_index,
  block_event_place_contingent: BlokkliComponent_event_place_contingent_index,
  block_expand_section: BlokkliComponent_expand_section_index,
  block_external_contact: BlokkliComponent_external_contact_index,
  block_government_contact: BlokkliComponent_government_contact_index,
  block_horizontal_line: BlokkliComponent_horizontal_line_index,
  block_horizontal_line__field_list_type_email: BlokkliComponent_horizontal_line_mjml,
  block_image: BlokkliComponent_image_index,
  block_image__field_list_type_email: BlokkliComponent_image_mjml,
  block_information_link_attachment__parent_block_expand_section: BlokkliComponent_information_link_attachment_Expandable,
  block_information_link_attachment: BlokkliComponent_information_link_attachment_index,
  block_link_list: BlokkliComponent_link_list_index,
  block_next_best_action: BlokkliComponent_next_best_action_index,
  block_place: BlokkliComponent_place_index,
  block_quote: BlokkliComponent_quote_index,
  block_section_title: BlokkliComponent_section_title_index,
  block_section_title__field_list_type_email: BlokkliComponent_section_title_mjml,
  block_table: BlokkliComponent_table_index,
  block_teaser: BlokkliComponent_teaser_index,
  block_teaser__field_list_type_email: BlokkliComponent_teaser_mjml,
  block_teaser_list: BlokkliComponent_teaser_list_index,
  block_text: BlokkliComponent_text_index,
  block_text__field_list_type_email: BlokkliComponent_text_mjml,
  block_blokkli_fragment: BlokkliComponent_blokkli_fragment_index
}

    
import BlokkliFragmentComponent_eurovision_faq from '/builds/bazinga/bs.ch/frontend/components/Paragraph/BlokkliFragment/EurovisionFaq/index.vue'
import BlokkliFragmentComponent_sound_of_religion from '/builds/bazinga/bs.ch/frontend/components/Paragraph/BlokkliFragment/SoundOfReligion/index.vue'
import BlokkliFragmentComponent_test_fragment from '/builds/bazinga/bs.ch/frontend/components/Paragraph/BlokkliFragment/TestFragment/index.vue'
import BlokkliFragmentComponent_zuzug from '/builds/bazinga/bs.ch/frontend/components/Paragraph/BlokkliFragment/Zuzug/index.vue'

const globalFragments: Record<string, any> = {
  fragment_eurovision_faq: BlokkliFragmentComponent_eurovision_faq,
  fragment_sound_of_religion: BlokkliFragmentComponent_sound_of_religion,
  fragment_test_fragment: BlokkliFragmentComponent_test_fragment,
  fragment_zuzug: BlokkliFragmentComponent_zuzug
}


const chunks: Record<string, () => Promise<any>> = {
  rare: () => import('#blokkli/chunk-rare'),
  gallery: () => import('#blokkli/chunk-gallery')
}

const chunkMapping: Record<string, string> = {
  "block_audio_player": "rare",
  "block_cleverreach_form": "rare",
  "block_csd_service": "rare",
  "block_event_list": "rare",
  "block_filter_page_item": "rare",
  "block_gallery": "gallery",
  "block_iframe": "rare",
  "block_logo_pictogramm": "rare",
  "block_mapbs_iframe": "rare",
  "block_popular_links": "rare",
  "block_publication_list": "rare",
  "block_video_remote": "rare"
}

const fragmentChunkMapping: Record<string, string> = {}

export function getBlokkliItemComponent(bundle: string, fieldListType?: string, parentBundle?: string): any {
  const forFieldListType = 'block_' + bundle + '__field_list_type_' + fieldListType
  if (global[forFieldListType]) {
    return global[forFieldListType]
  }
  if (parentBundle) {
    const forParentBundle = 'block_' + bundle + '__parent_block_' + parentBundle
    if (global[forParentBundle]) {
      return global[forParentBundle]
    }
  }
  const key = 'block_' + bundle
  if (global[key]) {
    return global[key]
  }
  const chunkName = chunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}

export function getBlokkliFragmentComponent(name: string): any {
  const key = 'fragment_' + name
  if (globalFragments[key]) {
    return globalFragments[key]
  }
  const chunkName = fragmentChunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}
